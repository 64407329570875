import customError from "../util/customError";

/* ----------- EXCEPTIONS ---------- */
export const UnableToConnectException = customError(
  "UnableToConnectException",
  "Unable to connect to the server."
);
export const ServerErrorException = customError(
  "ServerErrorException",
  "There was an error on the server."
);
export const NotFoundException = customError(
  "NotFoundException",
  "Endpoint not found."
);
export const AuthenticationException = customError(
  "AuthenticationException",
  "User is not authenticated."
);
export const BadRequestException = customError(
  "BadRequestException",
  "Bad request sent."
);
export const TimeoutException = customError(
  "TimeoutException",
  "The request timed out."
);

/* ----------- PRIVATE FUNCTIONS ---------- */
const ADDRESS = process.env.GATSBY_API_URL;

function getAuthHeader() {
  const existingTokensStr = localStorage.getItem("tokens");

  if (existingTokensStr) {
    const existingTokens = JSON.parse(existingTokensStr);
    return "JWT " + existingTokens.accessToken;
  }
}

function convertError(data) {
  if (data.errors && data.errors[0]) {
    if (Array.isArray(data.errors)) {
      return data.errors[0].reason || data.errors[0];
    } else if (typeof data.errors === "object") {
      const firstKey = Object.keys(data.errors)[0];
      return data.errors[firstKey];
    }
  }

  return "An error occurred";
}

async function request(path, settings) {
  let response;
  try {
    response = await fetch(`${ADDRESS}${path}`, settings);
  } catch (e) {
    console.log(e);
    throw new UnableToConnectException();
  }

  if (response.status === 404) throw new NotFoundException();
  if (response.status === 500) throw new ServerErrorException();
  if (response.status === 401) throw new AuthenticationException();
  if (response.status === 403) throw new AuthenticationException();
  if (response.status === 400)
    throw new BadRequestException(convertError(await response.json()));

  return response;
}

export async function get(path, body) {
  return await request(path, {
    headers: {
      Authorization: getAuthHeader(),
    },
    body: JSON.stringify(body),
  });
}

export async function del(path, body) {
  return await request(path, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthHeader(),
    },
    body: JSON.stringify(body),
  });
}

export async function post(path, body) {
  return await request(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthHeader(),
    },
    body: JSON.stringify(body),
  });
}

export async function put(path, body) {
  return await request(path, {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: getAuthHeader(),
    },
    body: JSON.stringify(body),
  });
}

export async function postFiles(path, files, body) {
  const formData = new FormData();

  files.forEach((file, index) => {
    formData.append(`file${index + 1}`, file);
  });

  Object.keys(body)
    .filter((key) => body[key])
    .forEach((key) => {
      formData.append(key, body[key]);
    });

  return await request(path, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      //Authorization: getAuthHeader(),
    },
    body: formData,
  });
}

/* ----------- PUBLIC FUNCTIONS ----------- */
export async function searchAITools(searchTerm) {
  const response = await get(`/search?searchTerm=${searchTerm}`);
  return await response.json();
}