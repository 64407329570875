import React, { useEffect, useContext } from "react";

import { getMe as apiGetMe, refreshToken as apiRefreshToken } from "./api";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

const AuthenticationProvider = ({ children }) => {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  useEffect(() => {
    const checkUserStatus = async () => {
      if (!state.user || !state.user.email) {
        if (state.loggedIn) {
          try {
            const data = await apiGetMe();
            if (data.user) {
              dispatch({
                type: "storeUser",
                user: data.user,
              });
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          try {
            const tokensStr = localStorage.getItem("tokens");
            let tokens = tokensStr && JSON.parse(tokensStr);

            if (tokens && tokens.accessToken && tokens.refreshToken) {
              const data = await apiRefreshToken(tokens.refreshToken);
              if (data.token.access_token) {
                dispatch({
                  type: "loggedIn",
                  tokens: {
                    accessToken: data.token.access_token,
                    refreshToken: data.token.refresh_token,
                  },
                });

                setInterval(async () => {
                  try {
                    const existingTokensStr = localStorage.getItem("tokens");

                    if (existingTokensStr) {
                      const existingTokens = JSON.parse(existingTokensStr);
                      const refreshTokenData = await apiRefreshToken(
                        existingTokens.refreshToken
                      );
                      dispatch({
                        type: "loggedIn",
                        tokens: {
                          accessToken: refreshTokenData.token.access_token,
                          refreshToken: refreshTokenData.token.refresh_token,
                        },
                      });
                    }
                  } catch (e) {
                    console.log(e);
                  }
                }, 600000);
              } else {
                dispatch({
                  type: "portalLoaded",
                });
              }
            } else {
              dispatch({
                type: "portalLoaded",
              });
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    };
    checkUserStatus();
  }, [dispatch, state.loggedIn, state.user]);

  return <>{children}</>;
};

export default AuthenticationProvider;
