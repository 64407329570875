import React from "react";

import AuthenticationProvider from "./src/services/authenticationProvider";

import GlobalContextProvider from "./src/context/GlobalContextProvider";

import "./src/styles/global.css";

export const wrapRootElement = ({ element }) => {
  return (
    <GlobalContextProvider>
      <AuthenticationProvider>{element}</AuthenticationProvider>
    </GlobalContextProvider>
  );
};
