exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-ai-tool-coupon-codes-js": () => import("./../../../src/pages/ai-tool-coupon-codes.js" /* webpackChunkName: "component---src-pages-ai-tool-coupon-codes-js" */),
  "component---src-pages-blog-search-js": () => import("./../../../src/pages/blog/search.js" /* webpackChunkName: "component---src-pages-blog-search-js" */),
  "component---src-pages-code-of-ethics-js": () => import("./../../../src/pages/code-of-ethics.js" /* webpackChunkName: "component---src-pages-code-of-ethics-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-editorial-policy-js": () => import("./../../../src/pages/editorial-policy.js" /* webpackChunkName: "component---src-pages-editorial-policy-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-disclaimer-js": () => import("./../../../src/pages/legal-disclaimer.js" /* webpackChunkName: "component---src-pages-legal-disclaimer-js" */),
  "component---src-pages-max-maybury-js": () => import("./../../../src/pages/max-maybury.js" /* webpackChunkName: "component---src-pages-max-maybury-js" */),
  "component---src-pages-modern-slave-statement-js": () => import("./../../../src/pages/modern-slave-statement.js" /* webpackChunkName: "component---src-pages-modern-slave-statement-js" */),
  "component---src-pages-peter-king-js": () => import("./../../../src/pages/peter-king.js" /* webpackChunkName: "component---src-pages-peter-king-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-review-guidelines-js": () => import("./../../../src/pages/review-guidelines.js" /* webpackChunkName: "component---src-pages-review-guidelines-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-our-guide-js": () => import("./../../../src/templates/ourGuide.js" /* webpackChunkName: "component---src-templates-our-guide-js" */),
  "component---src-templates-our-review-js": () => import("./../../../src/templates/ourReview.js" /* webpackChunkName: "component---src-templates-our-review-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

