import React from "react";

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const initialState = {
  category: {},
  user: {},
  portalLoaded: false,
  loggedIn: false,
};

function reducer(state, action) {
  console.log("action.type", action.type);
  switch (action.type) {
    case "portalLoaded": {
      return {
        ...state,
        portalLoaded: true,
      };
    }

    case "setCurrentCategory": {
      return {
        ...state,
        category: action.category,
      };
    }

    case "storeUser": {
      return {
        ...state,
        user: action.user,
      };
    }

    case "loggedIn": {
      localStorage.setItem("tokens", JSON.stringify(action.tokens));
      return {
        ...state,
        loggedIn: true,
        portalLoaded: true,
      };
    }

    case "logout": {
      localStorage.removeItem("tokens");
      return {
        ...state,
        loggedIn: false,
        user: {},
      };
    }

    case "upvoteReview": {
      let reviewVotes = state.user.reviewVotes;

      let existingReviewVote = reviewVotes.find(
        (_) => _.reviewId === action.reviewId
      );

      if (existingReviewVote) {
        existingReviewVote.type = "upvote";
      } else {
        reviewVotes.push({
          type: "upvote",
          reviewId: action.reviewId,
        });
      }

      return {
        ...state,
        user: {
          ...state.user,
        },
      };
    }

    case "downvoteReview": {
      let reviewVotes = state.user.reviewVotes;

      let existingReviewVote = reviewVotes.find(
        (_) => _.reviewId === action.reviewId
      );

      if (existingReviewVote) {
        existingReviewVote.type = "downvote";
      } else {
        reviewVotes.push({
          type: "downvote",
          reviewId: action.reviewId,
        });
      }

      return {
        ...state,
        user: {
          ...state.user,
          reviewVotes,
        },
      };
    }

    default:
      throw new Error("Bad Action Type");
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
